
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'X-Requested-With': 'XMLHttpRequest'
    }
});

if ($.fn.dataTable !== undefined) {
    $.extend(true, $.fn.dataTable.defaults, {
        responsive: {
            details: false
        },
        "ordering": true,
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [
            [1, 'asc']
        ]
    });

    $.fn.dataTable.render.ellipsis = function (cutoff) {
        return function (data, type, row) {
            return type === 'display' && data != null && data.length > cutoff ?
                data.substr(0, cutoff) + '…' :
                data;
        };
    };
    $.fn.dataTable.render.formatMoney = function (decimalCount = 2, decimal = ".", thousands = ",") {
        return function (data, type, row) {
            if (type === 'display' && data != null) {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
                const negativeSign = data < 0 ? "-" : "";
                let i = parseInt(data = Math.abs(Number(data) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;
                return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(data - i).toFixed(decimalCount).slice(2) : "");
            } else {
                return data;
            }
        };
    };

    $.fn.dataTable.render.lookup = function () {
        return function (data, type, row) {
            if (type === 'display' && data != null) {
                return "<a role='button' href='/" + data.logical_name + "/" + data.id + "'>" + data.name + "&nbsp;</a>";
            } else {
                return data;
            }
        };
    };

    $.fn.dataTable.render.lookupRow = function () {
        return function (data, type, row) {
            if (type === 'display' && data != null) {
                return "<a role='button' href='/" + row.logical_name + "/" + row.id + "'>" + data + "&nbsp;</a>";
            } else {
                return data;
            }
        };
    };

    $.fn.dataTable.render.lookupRowEdit = function () {
        return function (data, type, row) {
            if (type === 'display' && data != null) {
                return "<a role='button' href='/" + row.logical_name + "/" + row.id + "/edit'>" + data + "&nbsp;</a>";
            } else {
                return data;
            }
        };
    };

    $.fn.dataTable.render.phoneLink = function () {
        return function (data, type, row) {
            if (type === 'display' && data != null) {
                return "<a role='button' href='tel:" + data + "'>" + data + "</a>";
            } else {
                return data;
            }
        };
    };
}


//Hide invalid inputs on change
$('input.is-invalid').change(function () {
    $(this).removeClass('is-invalid');
});

//add radius on hide button group
$(".btn-group button:visible").first().addClass('radius-left').end().last().addClass('radius-right');

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "showDuration": "100",
    "hideDuration": "500",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "slideUp",
};

jconfirm.defaults = {
    theme: 'bootstrap',
    useBootstrap: true,
    backgroundDismiss: true,
    closeIcon: true,
    type: 'dark',
};

$('[data-toggle="tooltip"]').tooltip();